import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as storageConstants from '../Constants/storageConstant';
import * as commonServices from '../Services/common';
import { isNullOrEmpty } from '../Utils/stringUtils';
import { warn } from '../Wrapper/toast/toast';
import { PAGE_NOT_ACCESSABLE } from '../Constants/stringConstants';
import { CLIENTS, DASHBOARD, HELP, NOT_APPLICABLE, PROFILE, SYSTEM_SETTINGS, } from '../Constants/appConstants';

const ProtectedRoute = ({ Component, pageName }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAllowed, setIsAllowed] = useState(false);

  useEffect(() => {
    const userData = commonServices.getLocalData(storageConstants.USER_DATA);
    const isAuthenticated = !isNullOrEmpty(userData?.auth);

    if (!isAuthenticated) {
      setIsAllowed(true);
      if (location.pathname !== '/admin') navigate("/");
    } else if (isAuthenticated && (location.pathname === "/" || location.pathname === '/admin')) {
      navigate(commonServices.isClientAdmin() ? "/dashboard" : '/help');
    } else {
      checkPermission();
    }
  }, [location.pathname]);

  const checkPermission = () => {
    const permissions = commonServices.getLocalData(storageConstants.PERMISSIONS);
    const hasPermission = permissions?.some(permission => permission?.module?.toLowerCase() === pageName?.toLowerCase());
    const canAccess = hasPermission
      || (pageName === CLIENTS && commonServices.isSuperAdmin())
      || (pageName === DASHBOARD && (commonServices.isSuperAdmin() || commonServices.isClientAdmin()))
      || (pageName === SYSTEM_SETTINGS && commonServices.isClientAdmin())
      || pageName === HELP
      || pageName === PROFILE
      || pageName === NOT_APPLICABLE;

    setIsAllowed(canAccess);

    if (!canAccess) {
      navigate("/dashboard");
      warn(PAGE_NOT_ACCESSABLE);
    }
  };

  return (
    <>
      {isAllowed ? Component : null}
    </>
  );
}

export default ProtectedRoute;
