import * as storageConstants from '../Constants/storageConstant';
import { isNullOrEmpty } from '../Utils/stringUtils';
import * as appConstants from "../Constants/appConstants";
import * as apiHelper from "../Services/ApiHelper";
import { formatValue } from 'react-currency-input-field';
import { warn } from '../Wrapper/toast/toast';
import { BROWSER_STORAGE_ERROR } from '../Constants/stringConstants';

const sessionStorageArray = [];
const localStorageArray = [storageConstants.AUTH, storageConstants.USER_ROLE, storageConstants.PERMISSIONS, storageConstants.MASTER_DATA, storageConstants.SYSTEM_DATA, storageConstants.USER_DATA, storageConstants.DEPARTMENTS, storageConstants.ROLES, storageConstants.HOD];

export function getLocalData(name) {
    try {
        if (sessionStorageArray.includes(name)) {
            return (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? sessionStorage.getItem(name) : JSON.parse(sessionStorage.getItem(name));
        }
        else if (localStorageArray.includes(name)) {
            return (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? localStorage.getItem(name) : JSON.parse(localStorage.getItem(name));
        }
        return null;
    } catch (e) {
        return null;
    }
}

export function storeLocalData(name, data) {
    try {
        if (sessionStorageArray.includes(name)) {
            (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? sessionStorage.setItem(name, data) : sessionStorage.setItem(name, JSON.stringify(data, (k, v) => v === undefined ? null : v));
        }
        else if (localStorageArray.includes(name)) {
            (name === storageConstants.AUTH || name === storageConstants.USER_ROLE) ? localStorage.setItem(name, data) : localStorage.setItem(name, JSON.stringify(data, (k, v) => v === undefined ? null : v));
        }
        return true;
    } catch (e) {
        alert('Your web browser does not support storing settings locally. Some settings may not save or some features may not work properly for you.');
        return false;
    }
}

export function clearLocalStorageData(excludeKeys = []) {
    try {
        const allKeys = Object.keys(localStorage);

        allKeys.forEach(key => {
            if (!excludeKeys.includes(key)) {
                localStorage.removeItem(key);
            }
        });
        return true;
    } catch (e) {
        return false;
    }
}

export const getMasterData = () => {
    return getLocalData(storageConstants.MASTER_DATA);
}

export const getSystemData = async () => {
    return getLocalData(storageConstants.SYSTEM_DATA);
}

export function isEmailValid(email) {
    const regex = /^(([A-Za-z0-9](?!.*\.{2})[A-Za-z0-9_\-\.]+[A-Za-z0-9])|([A-Za-z0-9]{1,60}))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})(\]?)$/; /* eslint-disable-line */
    return regex.test(email);
}

export const preventPlusMinusInNumberInput = (e) => {
    // Prevent entry of minus sign (keycode 189) and plus sign (keycode 187)
    if (e.keyCode === 189 || e.keyCode === 109 || e.keyCode === 187 || e.keyCode === 107) {
        e.preventDefault();
    }
}

export const getTwoDecimalPlaces = (num) => {
    const n = num?.toString();
    return n.includes('.')
        ? n?.substring(0, n.indexOf('.') + 3)
        : n;
}

export function isValidAmount(amount, type = 'int', rounding = false) {
    const a = isNullOrEmpty(amount) ? (type === 'int' ? 0 : 0.00) : amount;
    const n = a?.toString().replace(/,/g, '');
    return !isNaN(n);
}

export const formattedValue = (val, prefix, allowDecimals = true) => formatValue({
    value: `${val}`,
    groupSeparator: ',',
    decimalSeparator: '.',
    prefix: prefix ? "₹" : "",
    intlConfig: prefix ? { locale: 'en-IN', currency: 'INR' } : null,
    decimalScale: (prefix || allowDecimals) ? 2 : null,
});

export function amountFormatter(amount, type = 'int', rounding = false) {
    if (isNaN(amount)) return amount;
    const a = isNullOrEmpty(amount) ? (type === 'int' ? 0 : 0.00) : amount;

    return type === 'float'
        ? a?.toLocaleString("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2, })
        : a?.toLocaleString("en-IN");
}

export function removeCommaFromAmount(amount, type = 'int', rounding = false) {
    const a = isNullOrEmpty(amount) ? (type === 'int' ? 0 : 0.00) : amount;
    const value = a?.toString().replace(/,/g, '');
    const v = getTwoDecimalPlaces(value);

    return type === 'int'
        ? parseInt(v)
        : parseFloat(v);
}

const darkenColor = (color, amount) => {
    let usePound = false;

    if (color[0] === "#") {
        color = color.slice(1);
        usePound = true;
    }

    const num = parseInt(color, 16);

    let r = (num >> 16) - amount;
    if (r < 0) r = 0;
    else if (r > 255) r = 255;

    let g = ((num >> 8) & 0x00FF) - amount;
    if (g < 0) g = 0;
    else if (g > 255) g = 255;

    let b = (num & 0x0000FF) - amount;
    if (b < 0) b = 0;
    else if (b > 255) b = 255;

    return (usePound ? "#" : "") + (r.toString(16).padStart(2, '0')) + (g.toString(16).padStart(2, '0')) + (b.toString(16).padStart(2, '0'));
};

export const setPrimarySecondaryColors = (color = '#5e81f4') => { // default primary
    const primaryColor = color;
    const secondaryColor = darkenColor(primaryColor, 20);

    document.documentElement.style.setProperty('--primary', primaryColor);
    document.documentElement.style.setProperty('--secondary', secondaryColor);
}

export const fetchMasterData = async (setLoading) => {
    // setLoading && setLoading(true);
    // await apiHelper.masterData().then(response => {
    //     setLoading && setLoading(false);
    //     if (response?.isSuccess) {
    //         try {
    //             storeLocalData(storageConstants.MASTER_DATA, response?.data?.data);
    //         } catch (e) {
    //             warn(BROWSER_STORAGE_ERROR);
    //         }
    //     }
    // });
}

export const fetchSystemData = async (setLoading) => {
    setLoading && setLoading(true);
    await apiHelper.systemData().then((response) => {
        setLoading && setLoading(false);
        if (response?.isSuccess) {
            try {
                storeLocalData(storageConstants.SYSTEM_DATA, response?.data);
            } catch (e) {
                warn(BROWSER_STORAGE_ERROR);
            }
        }
    });
};

export const isSuperAdmin = () => {
    const userData = getLocalData(storageConstants.USER_DATA);
    return userData?.role === appConstants.SUPER_ADMIN;
}

export const isClientAdmin = () => {
    const userData = getLocalData(storageConstants.USER_DATA);
    return userData?.role === appConstants.CLIENT_ADMIN;
}

export const storeDepartmentsInLocal = (depts = []) => {
    const options = depts?.map(d => ({
        label: d?.name,
        value: d?.id,
    }));

    storeLocalData(storageConstants.DEPARTMENTS, options);
    return options;
}

export const storeHODInLocal = (hod = []) => {
    const options = hod?.map(h => ({
        label: h?.name,
        value: h?.id,
    }));

    storeLocalData(storageConstants.HOD, options);
    return options;
}

export const getHODName = (hodId = '') => {
    const options = getLocalData(storageConstants.HOD);

    if (options && hodId) {
        return options?.filter(hod => hod?.value === parseInt(hodId))[0]?.label;
    } else return '';
}

export const storeRolesInLocal = (roles = []) => {
    const options = roles?.map(r => ({
        label: r?.name,
        value: r?.id,
    }));

    storeLocalData(storageConstants.ROLES, options);
    return options;
}

export const getTeacherRoleId = () => {
    const roles = getLocalData(storageConstants.ROLES);

    if (roles?.length > 0) {
        return roles?.filter(r => r?.label === appConstants.TEACHER)[0]?.value;
    } else return null;
}

export const getStudentRoleId = () => {
    const roles = getLocalData(storageConstants.ROLES);

    if (roles?.length > 0) {
        return roles?.filter(r => r?.label === appConstants.STUDENT)[0]?.value;
    } else return null;
}

export const getLoginTypeOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data) {
        return data?.loginTypes?.map(lt => ({
            label: lt?.name,
            value: lt?.id,
        }));
    } else {
        fetchSystemData();
    }
}

export const getModuleOptions = () => {
    const data = getLocalData(storageConstants.SYSTEM_DATA);

    if (data) {
        return data?.modules?.map(lt => ({
            label: lt?.name,
            value: lt?.id,
        }));
    } else {
        fetchSystemData();
    }
}

export const getRoleOptions = () => {
    const roles = getLocalData(storageConstants.ROLES);
    return roles?.length > 0 ? roles : [];
}

export const getDepartmentOptions = () => {
    const depts = getLocalData(storageConstants.DEPARTMENTS);
    return depts?.length > 0 ? depts : [];
}

export const attendanceLimitOptions = () => {
    return [
        { label: "1 Time", value: 1 },
        { label: "2 Times", value: 2 },
        { label: "3 Times", value: 3 },
        { label: "4 Times", value: 4 },
        { label: "5 Times", value: 5 },
        { label: "6 Times", value: 6 },
        { label: "7 Times", value: 7 },
        { label: "8 Times", value: 8 },
        { label: "9 Times", value: 9 },
        { label: "10 Times", value: 10 }
    ];
}

export const getUserStatusOptions = () => {
    return [
        { label: "Verified", value: "1" },
        { label: "Not Verified", value: "0" },
        { label: "Not Matched", value: "-1" }
    ];
}

export const getStatusNameFromId = (id) => {
    return getUserStatusOptions().filter(s => s.value === id)[0]?.label;
}

export const getStatusBackgroundColor = (id) => {
    return id === '1' ? 'var(--success)' : id === '-1' ? '#3a3aee' : id === '0' ? 'var(--fail)' : "";
}
