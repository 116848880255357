export const CLIENT_UNIQUE_CODE_MAX_LENGTH = 10;
export const LAT_LNG_MAX_LENGTH = 20;
export const MOBILE_MAX_LENGTH = 14;
export const ADDRESS_MAX_LENGTH = 250;
export const DESC_MAX_LENGTH = 300;
export const BASIC_MAX_LENGTH = 50;
export const SETTINGS_VALUE_LENGTH = 50;

export const PENDING = "PENDING";
export const CLOSED = "CLOSED";
export const COMPLETED = "COMPLETED";
export const AT_COUNSELLOR = "AT_COUNSELLOR";
export const AT_DOCTOR = "AT_DOCTOR";
export const AT_PHARMACY = "AT_PHARMACY";

export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";

export const DOCTOR = "DOCTOR";

export const SUPER_ADMIN = 'SUPER_ADMIN';
export const CLIENT_ADMIN = 'ADMIN';
export const STUDENT = 'STUDENT';
export const TEACHER = 'TEACHER';

export const DASHBOARD = "dashboard";
export const PROFILE = "profile";
export const CLIENTS = "clients";
export const DEPARTMENTS = "departments";
export const ATTENDANCE = "attendance";
export const USER_AND_ROLES = "user & roles";
export const SYSTEM_SETTINGS = "system_settings";
export const HELP = "help";
export const NOT_APPLICABLE = "not_applicable";

export const REPORTS = {
    TRENDS: {
        key: "trends-report",
        title: "Trends Report",
    },
    DEPARTMENT: {
        key: "department-report",
        title: "Department Report",
    },
    ATTENDANCE_DASHBOARD: {
        key: "attendance-dashboard",
        title: "Attendance Dashboard",
    },
    EMP_STD_ATTENDANCE: {
        key: "employee-student-attendance-report",
        title: "Employee/Student Attendance Report",
    },
    DPT_TEAM_ATTENDANCE: {
        key: "department-team-attendance-report",
        title: "Department/Team Attendance Report",
    }
}

export const ATTENDANCE_DASHBOARD = [
    { key: 'absentEmployees', label: 'Absent Employees' },
    { key: 'absentStudents', label: 'Absent Students' },
    { key: 'manualAttendance', label: 'Manual Attendance' },
    { key: 'presentEmployees', label: 'Present Employees' },
    { key: 'presentStudents', label: 'Present Students' }
];

export const attendanceStatusOptions = [
    { value: 'PRESENT', label: 'Present' },
    { value: 'ABSENT', label: 'Absent' },
    { value: 'MANUAL', label: 'Manual' }
];
